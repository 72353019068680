import React from 'react';
import { Typography } from '@material-ui/core';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import UserAdminViewContainer from './AgentAdminViewContainer';
import { Row, Col, RowCol } from '../ScreenSmith';
import { AOCard } from '../AOCard';
import { AOLinkButton } from '../AOLinkButton';
import {
  ROUTE_ADMINISTRATION,
  ROUTE_AGENT_MANAGEMENT_ADD_AGENT,
  ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT,
} from '../../constants/routes';

const AgentAdmin = () => {
  return (
    <Feature name={featureFlags.ACCESS_AGENT_MANAGEMENT}>
      <UserAdminViewContainer
        title={'Find an Agent Administration Page'}
        returnButtonRoute={ROUTE_ADMINISTRATION}
        returnButtonText="Administration Menu">
        <RowCol mb={2}>
          <AOCard>
            <Row spacing={4}>
              <Col xs={4}>
                <Typography color="textSecondary">
                  Welcome to the MSA Web Find an Agent Administration Home Page. The links to the
                  right offer access to all of the resouces you need to review or edit agent
                  location information.
                </Typography>
              </Col>
              <Col xs={8}>
                <RowCol mb={4}>
                  <AOLinkButton
                    route={ROUTE_AGENT_MANAGEMENT_ADD_AGENT}
                    style={{ fontSize: '1rem' }}>
                    Add Agent
                  </AOLinkButton>
                  <Typography color="textSecondary">
                    From this screen you may add new agents.
                  </Typography>
                </RowCol>
                <RowCol>
                  <AOLinkButton
                    route={ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT}
                    style={{ fontSize: '1rem' }}>
                    Search/Edit Agent
                  </AOLinkButton>
                  <Typography color="textSecondary">
                    From this page you may find agents and edit their names, email addresses,
                    addresses, GPS coordinates, phone or fax numbers.
                  </Typography>
                </RowCol>
              </Col>
            </Row>
          </AOCard>
        </RowCol>
      </UserAdminViewContainer>
    </Feature>
  );
};

export default AgentAdmin;
