import { createTheme } from '@material-ui/core/styles';
import { primary, secondary, denimBlue, jade, defaultCountButton } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      light: primary.light,
      main: primary.main,
      dark: primary.dark,
      contrastText: '#ffffff',
    },
    secondary: {
      light: secondary.light,
      main: secondary.main,
      dark: secondary.dark,
      contrastText: '#ffffff',
    },
    text: {
      primary: `rgba(0,0,0,0.87)`,
      secondary: `rgba(0,0,0,0.6)`,
      disable: `rgba(0,0,0,0.38)`,
    },
    background: { default: '#f2f3f4' },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
    grey: {
      grey100: `#f5f5f5`,
    },
    green: {
      recommended: '#00857A',
    },
  },
  customPalette: {
    textLabel: {
      prefixIcon: jade[700],
      prefixIconSize: `rem(8)`,
    },
    countButton: {
      renewalOverTen: {
        primary: jade[700],
      },
      defaultColor: defaultCountButton,
    },
    titleBar: {
      container: denimBlue[800],
      textColor: `#fff`,
      iconColor: `#fff`,
    },
  },
});

export default theme;
