import React, { useContext } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

import agentContext from '../../contexts/agent.context';

const RedesignSwitch = () => {
  const { toggleHeader, userPreferences } = useContext(agentContext);

  const isNewNavigation = userPreferences?.redesignHeader;
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isNewNavigation ?? false}
          onChange={toggleHeader}
          name="redesignHeader"
          color="primary"
        />
      }
      label="New Agents Only"
    />
  );
};

export default RedesignSwitch;
