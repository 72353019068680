import React from 'react';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { BrandFolder } from '../BrandFolder';

const Underwriting = () => (
  <Feature name={featureFlags.ACCESS_UNDERWRITING}>
    <BrandFolder title="Underwriting Resources" typeOfContainer="Underwriting" />
  </Feature>
);

export default Underwriting;
