import { useContext } from 'react';
import PropTypes from 'prop-types';

import agentContext from '../../contexts/agent.context';

const Feature = ({ children, name }) => {
  const { FeatureFlags } = useContext(agentContext);

  const featureEnabled = FeatureFlags?.[name] ?? false;
  return featureEnabled ? children : null;
};

Feature.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default Feature;
