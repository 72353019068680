import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { ViewContainer } from '../Layout';
import { AOCard } from '../AOCard';
import BrandFolderFilters from './BrandFolderFilters';
import BrandFolderGrid from './BrandFolderGrid';
import { TYPE_OF_CONTAINER_FILTER_MAPPING } from './Filters/filterHelpers';

const BrandFolder = ({
  titleAsSubtitle,
  typeOfContainer,
  showSupplyOrderButton,
  showViewMultipleButton,
  title,
}) => {
  const [searchCriteria, setSearchCriteria] = useState({});

  const initialQueryRef = useRef({});
  const resetFuncRef = useRef({});

  const handleResetOnClick = () => {
    for (const key of Object.keys(resetFuncRef.current)) {
      resetFuncRef.current[key]();
    }
    setSearchCriteria(initialQueryRef.current);
  };

  const handleSetSearchCriteria = (key, value) => {
    setSearchCriteria((prevState) => ({ ...prevState, [key]: value }));
  };

  const setResetFunc = (key, value) => {
    resetFuncRef.current[key] = value;
  };

  const setInitialQuery = (key, value) => {
    initialQueryRef.current[key] = value;
  };

  // function to build searchCriteria in format that BrandFolder API expects the search_criteria string
  const getFilterCriteria = (searchCriteria) => {
    const filterCount = TYPE_OF_CONTAINER_FILTER_MAPPING[typeOfContainer].length;
    const searchCriteriaArray = ['approved:true', 'expired:false'];
    for (const key in searchCriteria) {
      // if name search filters is empty, do not include it
      if (key === 'name' && searchCriteria[key]?.trim()?.length === 0) {
        continue;
      }
      // for all other filters, send the selected value(s) or empty string if none selected
      else {
        searchCriteriaArray.unshift(
          `${key}:(${searchCriteria[key].length > 0 ? searchCriteria[key] : '"**NONE**"'})`,
        );
      }
    }
    const searchCriteriaString = searchCriteriaArray.join(' AND ');

    return Object.keys(searchCriteria).length >= filterCount ? `(${searchCriteriaString})` : '';
  };

  const isUnderwriting = typeOfContainer === 'Underwriting';

  return (
    <ViewContainer title={!titleAsSubtitle ? title : ''}>
      {titleAsSubtitle && (
        <Box mb={3}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <AOCard
        style={{
          overflow: 'visible',
          position: 'relative',
          marginTop: isUnderwriting ? '-1rem' : '',
        }}>
        <Box display="flex" p={1}>
          <Box mr={3}>
            <BrandFolderFilters
              typeOfContainer={typeOfContainer}
              handleResetOnClick={handleResetOnClick}
              handleSetSearchCriteria={handleSetSearchCriteria}
              setInitialQuery={setInitialQuery}
              setResetFunc={setResetFunc}
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <BrandFolderGrid
              showViewMultipleButton={showViewMultipleButton}
              searchCriteria={getFilterCriteria(searchCriteria)}
              showSupplyOrderButton={showSupplyOrderButton}
            />
          </Box>
        </Box>
      </AOCard>
    </ViewContainer>
  );
};

BrandFolder.defaultProps = {
  showSupplyOrderButton: false,
  showViewMultipleButton: true,
  titleAsSubtitle: false,
};

BrandFolder.propTypes = {
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
  showSupplyOrderButton: PropTypes.bool,
  showViewMultipleButton: PropTypes.bool,
  title: PropTypes.string,
  titleAsSubtitle: PropTypes.bool,
};

export default BrandFolder;
