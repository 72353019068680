import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import RedesignHeader from '../Header/RedesignHeader';
import RedesignNavigation from '../Navigation/RedesignNavigation';
import { Footer } from '../Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    padding: theme.spacing(3),
  },
}));

const RedesignUIShell = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RedesignHeader />
      <RedesignNavigation />
      <div className={classes.container}>
        <main className={classes.content}>
          <Toolbar />
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

RedesignUIShell.propTypes = {
  children: PropTypes.node,
};

export default RedesignUIShell;
