const featureFlags = {
  ACCESS_AGENCY_SECURITY_USER_ADMIN: 'agencySecurityUserAdministration',
  ACCESS_AGENT_MANAGEMENT: 'Access_Agent_Management',
  ACCESS_APNE_REPORT: 'Access_AP&E_Report',
  ACCESS_APP_ADMIN: 'Access_App_Admin',
  ACCESS_CONTENT_MGMT_ADMIN: 'Acccess_Content_Management',
  ACCESS_NEWS_ADMIN: 'newsAdministration',
  ACCESS_RESOURCE_MGMT_ADMIN: 'Access_Resource_Management',
  ACCESS_SUPER_ADMIN: 'superAdmin',
  ACCESS_TAR_REPORT: 'Access_TransactionActivity_Report',
  ACCESS_USER_ADMIN: 'userAdministration',
  ACCESS_USER_INTERNAL: 'internalUser',
  ACCESS_USER_IS_AGENT: 'isAgent',
  ACCESS_MARKETING: 'Access_Marketing',
  ACCESS_TRAINING: 'Access_Training',
  ACCESS_UNDERWRITING: 'Access_Underwriting',
  AOLS_MARKETING: 'AOLS_Marketing',
  AOLS_TRAINING: 'AOLS_Training',
  AOLS_UNDERWRITING: 'AOLS_Underwriting',
  ENABLE_REDESIGN: 'Enable_Redesign',
};

export default featureFlags;
