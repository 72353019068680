import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ViewContainer, FullHeightMuiPaper } from '../Layout';
import { AOAlert } from '../AOAlert';
import { AOLinkButton } from '../AOLinkButton';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import AppConfigContext from '../../contexts/appConfig.context';
import { handleStateXltOnClick } from '../CommonUtilityServices/DownloadFileService';

const PLUmbrella = () => {
  const { legacyUrl } = useContext(AppConfigContext);

  const [redirectError, setRedirectError] = useState(null);

  const handlePlUmbrellaRedirect = () => {
    const requestBody = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Umbrella Quick Quote',
      DetailedContext: [],
    };

    setRedirectError(null);
    redirectUrlFunction(requestBody, 'POST', null, setRedirectError, setRedirectError);
  };

  return (
    <ViewContainer title="Personal Umbrella Tools and Information">
      {redirectError && <AOAlert severity={'error'}>{redirectError}</AOAlert>}
      <Paper>
        <Box pt={3} px={3}>
          <Paper variant="outlined" square>
            <Box display={'flex'} justifyContent={'center'} pt={3}>
              <AOLinkButton routeLegacy="/announcements/PL">
                News Alert for Product Changes
              </AOLinkButton>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={3}>
              <AOLinkButton routeLegacy="/pl/PL/status/Active">
                Underwriting Guidelines
              </AOLinkButton>
            </Box>
          </Paper>
        </Box>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FullHeightMuiPaper variant="outlined" square>
                <Box p={3}>
                  <Typography variant="h6">Regions: New England. Northeast, Southeast</Typography>
                  <Typography>
                    {/* This should have a 'campaign' icon as startAdornment, but the icon is only present in MUI 5, not 4. */}
                    <Box fontSize={12} color="text.secondary" mt={1.5}>
                      UMBRELLA RATING TOOL AND APPLICATION
                    </Box>
                  </Typography>
                  <Box mt={0.75}>
                    <AOLinkButton onClick={handlePlUmbrellaRedirect}>
                      Try Our New Umbrella Quoting Experience
                    </AOLinkButton>
                  </Box>
                  <Typography>
                    <Box fontSize={11}>Get a quick quote in under a minute</Box>
                  </Typography>
                </Box>
              </FullHeightMuiPaper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FullHeightMuiPaper variant="outlined" square>
                <Box p={3}>
                  <Typography variant="h6">Region: Western</Typography>
                  <Typography>
                    <Box fontSize={12} color="text.secondary" mt={1.5}>
                      UMBRELLA RATING TOOL AND APPLICATION
                    </Box>
                  </Typography>
                  <Box mt={0.75}>
                    <AOLinkButton
                      onClick={() =>
                        handleStateXltOnClick(
                          legacyUrl,
                          'templates/partials/desktop/pl/PL_AMIC_Umbrella_Rating_Tool.xlt',
                        )
                      }>
                      Arizona, Idaho, Minnesota
                    </AOLinkButton>
                  </Box>
                  <Box mt={0.75}>
                    <AOLinkButton
                      onClick={() =>
                        handleStateXltOnClick(
                          legacyUrl,
                          'templates/partials/desktop/pl/PL_AMIC_Umbrella_Rating_Tool_WA.xlt',
                        )
                      }>
                      Washington
                    </AOLinkButton>
                  </Box>
                  <Box mt={0.75}>
                    <AOLinkButton
                      onClick={() =>
                        handleStateXltOnClick(
                          legacyUrl,
                          'templates/partials/desktop/pl/PL_AMIC_Umbrella_Rating_Tool_WI.xlt',
                        )
                      }>
                      Wisconsin
                    </AOLinkButton>
                  </Box>
                </Box>
              </FullHeightMuiPaper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ViewContainer>
  );
};

export default PLUmbrella;
