import React from 'react';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { BrandFolder } from '../BrandFolder';

const AgencyToolkitTrainingMaterials = () => {
  return (
    <div>
      <Feature name={featureFlags.ACCESS_TRAINING}>
        <BrandFolder title="Training Materials" typeOfContainer="Training" titleAsSubtitle />
      </Feature>
      <Feature name={featureFlags.AOLS_TRAINING}>
        <div>AgencyToolkitTrainingMaterials</div>
      </Feature>
    </div>
  );
};

export default AgencyToolkitTrainingMaterials;
