import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { rem } from 'polished';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 150,
    maxWidth: 350,
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(45deg, ${theme.palette.grey.grey100} 25%, transparent 25%), linear-gradient(-45deg, ${theme.palette.grey.grey100} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${theme.palette.grey.grey100} 75%), linear-gradient(-45deg, transparent 75%, ${theme.palette.grey.grey100} 75%)`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    position: 'relative',
    cursor: 'pointer',
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    height: '12rem',
    overflow: 'hidden',
    '&:hover img': { opacity: '0.3' },
    '&:hover div': { opacity: '0.5' },
    '&:hover .brandfolder-card-view-button': { opacity: '1' },
    '&:hover .brandfolder-card-view-text': { opacity: '1' },
  },
  image: {
    maxHeight: '90%',
    maxWidth: '90%',
  },
  overlay: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    opacity: '0',
    transition: '.5s ease',
    backgroundColor: theme.palette.common.black,
  },
  viewButton: {
    transition: '.5s ease',
    opacity: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    borderRadius: rem(25),
    border: `${rem(2)} solid ${theme.palette.common.white}`,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: rem(14),
    padding: `${rem(16)} ${rem(32)}`,
    textTransform: 'uppercase',
  },
  label: {
    display: 'inline-block',
    color: theme.palette.text.primary,
    paddingLeft: rem(8),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& .MuiTypography-body1': {
      fontSize: rem(12),
      fontWeight: 500,
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  extension: {
    color: theme.palette.text.secondary,
    borderRadius: rem(15),
    fontWeight: 'bold',
    fontSize: rem(8),
    padding: rem(4),
    border: `${rem(2)} solid ${theme.palette.grey.grey100}`,
    backgroundColor: theme.palette.grey.grey100,
    textTransform: 'uppercase',
  },
}));

const BrandFolderCard = ({ checked, document, handleToggleChecked }) => {
  const classes = useStyles();
  const docId = document?.asset_id;
  const docAttributes = document?.attachments[0]?.attributes;

  const handleOpenFileInNewTab = () => {
    window.open(docAttributes.url, '_blank');
  };

  return (
    <Card className={classes.root} id={`brandfolder-card-${docId}`}>
      <CardContent className={classes.cardContent} onClick={handleOpenFileInNewTab}>
        <img className={classes.image} src={docAttributes?.thumbnail_url} alt="doc thumbnail" />
        <div className={classes.overlay}>
          <div className={`${classes.viewButton} brandfolder-card-view-button`}>
            <div className={`${classes.text} brandfolder-card-view-text`}>View</div>
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Tooltip title={document.attributes.name} aria-label={document.attributes.name}>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={checked}
                onChange={() =>
                  handleToggleChecked(docId, docAttributes?.url, document.attributes.name)
                }
                name={document.attributes.name}
              />
            }
            label={document?.attributes?.name}
          />
        </Tooltip>
      </CardActions>
    </Card>
  );
};

BrandFolderCard.propTypes = {
  checked: PropTypes.bool,
  document: PropTypes.object,
  handleToggleChecked: PropTypes.func,
};

export default BrandFolderCard;
