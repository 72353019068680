import axios from 'axios';
import _ from 'lodash';
import { GET_REDIRECT_URL, REDIRECT_TO_NEW_TAB } from '../../constants/api';
import OpenWindowService from './OpenWindowService';

const redirectUrlFunction = async (
  _requestBody,
  _httpMethod,
  _windowName = null,
  _setError = () => {},
  _setIsRedirectErrorVisible = () => {},
  _redirectType = REDIRECT_TO_NEW_TAB,
) => {
  _setError(null);
  _setIsRedirectErrorVisible(false);
  try {
    if (_httpMethod === 'POST') {
      const redirectResponse = await axios.post(`${GET_REDIRECT_URL}`, _requestBody);
      if (redirectResponse !== undefined && redirectResponse != null) {
        const redirectData = redirectResponse.data.RedirectDestination;

        if (_.isNil(redirectData.statusMessage) || _.isEmpty(redirectData.statusMessage)) {
          if (redirectData.redirectAllowed) {
            // needs a proper fix on the API response - method key for all redirectUrls, tracked under https://amfament.atlassian.net/browse/E1PMAO-3177
            // these cases needs to be moved into the function below
            if (
              redirectData.url.includes('.msainsurance.com/signin/forgot-password') ||
              redirectData.url.includes('/producer-engage/') ||
              redirectData.url.includes('mainstreetprotectionplus') ||
              redirectData.url.includes('pdwebv.msa')
            ) {
              _httpMethod = redirectData.method;
            }
            return OpenWindowService(
              redirectData.url,
              _httpMethod, // TODO: use correct method.
              redirectData.body,
              _windowName,
              _redirectType,
              redirectData.queryString,
            );
          } else {
            _setIsRedirectErrorVisible(true);
            _setError(redirectData.statusMessage);
          }
        } else {
          _setIsRedirectErrorVisible(true);
          _setError(redirectData.statusMessage);
        }
      }
    }
  } catch (error) {
    _setIsRedirectErrorVisible(true);
    if (error) {
      if (error.response && error.response.data) {
        _setError(error.response.data.message);
      } else {
        _setError(error.message);
      }
    } else {
      _setError(
        'There was a problem processing this request. We are sorry for the inconvenience. Please contact the Help Desk at 1-877-927-5672 or try again later.',
      );
    }
  }
};

// This function honors the response method after receiving a response from /redirectUrl
const redirectUrlFunctionWithResponseMethod = async (
  _requestBody,
  _windowName = null,
  _setError = () => {},
  _redirectType = REDIRECT_TO_NEW_TAB,
) => {
  _setError(null);
  try {
    const redirectResponse = await axios.post(`${GET_REDIRECT_URL}`, _requestBody);

    if (redirectResponse !== undefined && redirectResponse != null) {
      const redirectData = redirectResponse.data.RedirectDestination;
      const _httpMethod = redirectData.method ?? 'POST';

      if (_.isNil(redirectData.statusMessage) || _.isEmpty(redirectData.statusMessage)) {
        if (redirectData.redirectAllowed) {
          return OpenWindowService(
            redirectData.url,
            _httpMethod,
            redirectData.body,
            _windowName,
            _redirectType,
            redirectData.queryString,
          );
        } else {
          _setError(redirectData.statusMessage);
        }
      } else {
        _setError(redirectData.statusMessage);
      }
    }
  } catch (error) {
    if (error) {
      if (error.response && error.response.data) {
        _setError(error.response.data.detail);
      } else {
        _setError(error.message);
      }
    } else {
      _setError(
        'There was a problem processing this request. We are sorry for the inconvenience. Please contact the Help Desk at 1-877-927-5672 or try again later.',
      );
    }
  }
};

export { redirectUrlFunction, redirectUrlFunctionWithResponseMethod };
