import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';

import { APIRequestHandler } from '../APIRequestHandler';
import { LegalAgreement } from '../LegalAgreement';

import AgentContext from '../../contexts/agent.context';
import AppConfigContext from '../../contexts/appConfig.context';

import { SESSION_URL } from '../../constants/api';

const AppConfigProvider = ({ children }) => {
  const [{ data: userSession, loading: sessionLoading, error: sessionError }] = useAxios({
    method: 'post',

    url: `${SESSION_URL}`,
  });

  const [agentData, setAgentData] = useState({});
  const [appConfig, setAppConfig] = useState({});

  useEffect(() => {
    if (!sessionLoading && !_.isNil(userSession)) {
      const {
        PersonalData,
        UserID,
        LegacyWebUrl,
        LegacyWebUrlDomain,
        Locations,
        Flags,
        FeatureFlags,
      } = userSession;
      const agentData = {
        ...PersonalData,
        UserID,
        Locations,
        Flags,
        FeatureFlags,
        userPreferences: { redesignHeader: false },
      };
      setAgentData(agentData);
      setAppConfig({
        legacyUrl: LegacyWebUrl.substr(0, LegacyWebUrl.length - 1),

        legacyWebUrlDomain: LegacyWebUrlDomain,
      }); // remove last char of legacy url "/"
    }
  }, [sessionLoading]);

  const hideLegalAgreementModal = () => {
    setAgentData((prevState) => ({ ...prevState, Flags: { ...prevState.Flags, flgLegal: true } }));
  };

  const toggleHeader = () => {
    setAgentData((prevState) => ({
      ...prevState,
      userPreferences: { redesignHeader: !prevState.userPreferences.redesignHeader },
    }));
  };

  return (
    <APIRequestHandler loading={sessionLoading} error={sessionError}>
      <AppConfigContext.Provider value={appConfig}>
        {agentData?.Flags?.flgLegal === false ? (
          <LegalAgreement hideLegalAgreementModal={hideLegalAgreementModal} />
        ) : (
          <AgentContext.Provider value={{ ...agentData, toggleHeader }}>
            {children}
          </AgentContext.Provider>
        )}
      </AppConfigContext.Provider>
    </APIRequestHandler>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.node,
};

export default AppConfigProvider;
