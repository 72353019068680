import React from 'react';
import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { BrandFolder } from '../BrandFolder';

const AgencyToolkitMarketingMaterials = () => {
  return (
    <div>
      <Feature name={featureFlags.ACCESS_MARKETING}>
        <BrandFolder
          title="Marketing Materials"
          typeOfContainer="Marketing"
          titleAsSubtitle
          showSupplyOrderButton
        />
      </Feature>
      <Feature name={featureFlags.AOLS_TRAINING}>
        <div>AgencyToolkitTrainingMaterials</div>
      </Feature>
    </div>
  );
};

export default AgencyToolkitMarketingMaterials;
