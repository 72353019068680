import { rem } from 'polished';

import { Box, styled } from '@material-ui/core';

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: `${rem(0)}`,
}));
const StyledFooterMain = styled(Box)({
  padding: `${rem(32)}`,
});
const StyledFooterFoot = styled(Box)({
  paddingTop: `${rem(32)}`,
  paddingRight: `${rem(32)}`,
  paddingLeft: `${rem(32)}`,
  paddingBottom: 0,
});

export { StyledFooter, StyledFooterMain, StyledFooterFoot };
