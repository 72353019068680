import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';

import { APIRequestHandler } from '../APIRequestHandler';
import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import { SvgLogo } from '../SvgLogo';
import AgentDisplay from './AgentDisplay';
import AgentLogout from './AgentLogout';
import { handleClickLogout } from './Header.api';

import agentContext from '../../contexts/agent.context';

import {
  StyledActions,
  StyledHeader,
  StyledIconButtonContainer,
  StyledMenuIcon,
  StyledSvgLogoDestination,
  StyledToolbar,
} from './Header.styled';
import { AOCircularProgress } from '../AOCircularProgress';

const HeaderComponents = {
  AgentDisplay,
  AgentLogout,
  StyledActions,
  StyledHeader,
  StyledIconButtonContainer,
  StyledMenuIcon,
  StyledSvgLogoDestination,
  StyledToolbar,
  SvgLogo,
};

const Header = () => {
  const { oktaAuth } = useOktaAuth();
  const agentContextObj = useContext(agentContext);
  const [agentName, setAgentName] = useState('Agent Name');
  const [loggingOff, setLoggingOff] = useState(false);

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('commonHeader');

  const headerState = {
    agentName,
  };

  const callbackFunctions = {
    handleClickLogout: () => handleClickLogout(oktaAuth, setLoggingOff),
  };

  useEffect(() => {
    if (!_.isEmpty(agentContextObj) && !_.isEmpty(agentContextObj.Name)) {
      setAgentName(`${agentContextObj.Name.First} ${agentContextObj.Name.Last}`);
    }
  }, [agentContextObj]);

  return (
    <APIRequestHandler loading={loadingSd}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={HeaderComponents}
          stateMap={headerState}
          functionMap={callbackFunctions}
        />
      )}
      <AOCircularProgress visible={loggingOff}>Logging off...</AOCircularProgress>
    </APIRequestHandler>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  mobileControlFunc: PropTypes.func,
};
export default Header;
