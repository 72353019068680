import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';

import { Col, Row, RowCol } from '../../ScreenSmith';
import { AOTextField } from '../../AOTextField';
import { AOButton } from '../../AOButton';
import DocumentRow from './DocumentRow';
import { BRANDFOLDER_ORDER_ASSETS } from '../../../constants/api';
import AppDataContext from '../../../contexts/appData.context';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24,
  },

  rowItems: {
    display: 'flex',
    flexDirection: 'row',
  },

  greyText: {
    color: theme.palette.text.secondary,
  },
}));

const MAX_DOCUMENT_ROWS = 10;

const SupplyOrdering = ({ selectedDocuments, setSelectedDocuments, setShowSupplyOrderModal }) => {
  const initDocumentData = () => {
    const docs = Object.fromEntries(Object.entries(selectedDocuments).slice(0, MAX_DOCUMENT_ROWS));
    return docs;
  };

  const [documents, setDocuments] = useState(initDocumentData);
  const [loading, setLoading] = useState(false);

  const { handleSetSnackbarData } = useContext(AppDataContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleDeleteDoc = (docId) => {
    const tempDocs = { ...documents };
    if (tempDocs[docId]) {
      delete tempDocs[docId];
    }
    setDocuments(tempDocs);
  };

  const constructRequestBody = (data) => {
    let shippingDetails = {};
    let items = [];
    shippingDetails.name = data.firstName + ' ' + data.lastName;
    shippingDetails.agencyName = data.agencyName;
    shippingDetails.agencyNumber = data.agencyNumber;
    shippingDetails.address1 = data.shippingAddress;
    shippingDetails.city = data.city;
    shippingDetails.state = data.state;
    shippingDetails.zip = data.zipCode;
    shippingDetails.email = data.email;
    shippingDetails.telephone = data.phone;
    shippingDetails.comments = `${data.printingNotes ?? ''}`;
    Object.entries(documents).map(([key, value]) => {
      items.push({
        agtFormNo: value.docUrl,
        description: value.docName,
        quantity: parseInt(data[key]),
      });
    });
    return { shippingDetails: shippingDetails, items: items };
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const requestBody = constructRequestBody(data);
      const { status } = await axios.post(BRANDFOLDER_ORDER_ASSETS, requestBody);

      if (status === 200) {
        handleSetSnackbarData({
          message:
            'Thank you! Your order has been received. You will receive an email shortly with the details of your order.',
          severity: 'success',
        });
      } else {
        handleSetSnackbarData({
          message:
            'There was a problem processing this request. Please try again later or contact customer service.',
          severity: 'error',
        });
      }
      setShowSupplyOrderModal(false);
      setSelectedDocuments({});
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RowCol className={useStyles.container}>
      <RowCol mb={3}>
        <Col xs={12}>
          <Typography variant="h5">Order Marketing Supplies</Typography>
        </Col>
      </RowCol>
      <RowCol mb={2}>
        {Object.entries(documents).map(([key, value]) => {
          return (
            <Controller
              key={key}
              name={key}
              control={control}
              rules={{ required: 'Quantity is required' }}
              render={({ field }) => (
                <DocumentRow
                  {...field}
                  inputRef={field.ref}
                  docName={value.docName}
                  docId={key}
                  error={errors?.[key]?.message ? true : false}
                  handleDeleteDoc={handleDeleteDoc}
                  helperText={errors?.[key]?.message}
                />
              )}
            />
          );
        })}
      </RowCol>
      <RowCol mb={2}>
        <Divider variant="fullWidth" />
      </RowCol>
      <Row mb={2} className={useStyles.rowItems} spacing={2}>
        <Col xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'First Name is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="first-name"
                label="First Name"
                fullWidth
                error={errors?.firstName?.message ? true : false}
                helperText={errors?.firstName?.message}
              />
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'Last Name is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="last-name"
                label="Last Name"
                fullWidth
                error={errors?.lastName?.message ? true : false}
                helperText={errors?.lastName?.message}
              />
            )}
          />
        </Col>
      </Row>

      <Row mb={2} className={useStyles.rowItems} spacing={2}>
        <Col xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            rules={{ required: 'Email is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="email"
                label="Email"
                fullWidth
                error={errors?.email?.message ? true : false}
                helperText={errors?.email?.message}
              />
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Controller
            name="phone"
            control={control}
            rules={{ required: 'Phone is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="phone"
                label="Phone"
                fullWidth
                error={errors?.phone?.message ? true : false}
                helperText={errors?.phone?.message}
              />
            )}
          />
        </Col>
      </Row>
      <Row mb={2} className={useStyles.rowItems} spacing={2}>
        <Col xs={12} md={6}>
          <Controller
            name="agencyNumber"
            control={control}
            rules={{ required: 'Agency Number is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="agency-number"
                label="Agency Number"
                fullWidth
                error={errors?.agencyNumber?.message ? true : false}
                helperText={errors?.agencyNumber?.message}
              />
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Controller
            name="agencyName"
            control={control}
            rules={{ required: 'Agency Name is required' }}
            render={({ field }) => (
              <AOTextField
                {...field}
                id="agency-name"
                label="Agency Name"
                fullWidth
                error={errors?.agencyName?.message ? true : false}
                helperText={errors?.agencyName?.message}
              />
            )}
          />
        </Col>
      </Row>
      <RowCol mb={2}>
        <Controller
          name="shippingAddress"
          control={control}
          rules={{ required: 'Shipping Address is required' }}
          render={({ field }) => (
            <AOTextField
              {...field}
              id="shipping-address"
              label="Shipping Address"
              fullWidth
              error={errors?.shippingAddress?.message ? true : false}
              helperText={errors?.shippingAddress?.message}
            />
          )}
        />
        <Typography color="textSecondary" component="div">
          <Box ml={1.75} fontSize={'0.75rem'}>
            Do not use P.O. Boxes for shipping purposes.
          </Box>
        </Typography>
      </RowCol>
      <RowCol mb={2} className={useStyles.rowItems}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Controller
              name="city"
              control={control}
              rules={{ required: 'City is required' }}
              render={({ field }) => (
                <AOTextField
                  {...field}
                  id="city"
                  label="City"
                  error={errors?.city?.message ? true : false}
                  helperText={errors?.city?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="state"
              control={control}
              rules={{ required: 'State is required' }}
              render={({ field }) => (
                <AOTextField
                  {...field}
                  id="state-input"
                  label="State"
                  error={errors?.state?.message ? true : false}
                  helperText={errors?.state?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="zipCode"
              control={control}
              rules={{ required: 'Zip Code is required' }}
              render={({ field }) => (
                <AOTextField
                  {...field}
                  id="zip-code"
                  label="Zip Code"
                  error={errors?.zipCode?.message ? true : false}
                  helperText={errors?.zipCode?.message}
                />
              )}
            />
          </Box>
        </Box>
      </RowCol>
      <RowCol mb={2}>
        <Controller
          name="printingNotes"
          control={control}
          render={({ field }) => (
            <AOTextField {...field} id="printing-notes" label="Printing Notes" fullWidth />
          )}
        />
      </RowCol>
      <RowCol mb={2}>
        <Typography color="textSecondary">
          Clicking the Create Request button will generate a pre-filled email for you to send to our
          fulfillment team.
        </Typography>
      </RowCol>
      <RowCol mb={2}>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <AOButton
              id="supply-order-dialog-cancel-button"
              name="cancel"
              variant="text"
              color="secondary"
              onClick={() => setShowSupplyOrderModal(false)}>
              Cancel
            </AOButton>
          </Box>
          <Box>
            <AOButton
              id="supply-order-dialog-request-button"
              name="createRequest"
              variant="text"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}>
              Create Request
            </AOButton>
          </Box>
        </Box>
      </RowCol>
    </RowCol>
  );
};

SupplyOrdering.propTypes = {
  selectedDocuments: PropTypes.object,
  setSelectedDocuments: PropTypes.func,
  setShowSupplyOrderModal: PropTypes.func,
};

export default SupplyOrdering;
