import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { Typography, Box, IconButton, Avatar, Menu, MenuItem } from '@material-ui/core';

import RedesignSwitch from '../Header/RedesignSwitch';
import AppDataContext from '../../contexts/appData.context';
import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { handleClickLogout } from './Header.api';

const AgentDisplay = ({ agentDisplayName, agentInitials, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { oktaAuth } = useOktaAuth();
  const { setLoadingMessage } = useContext(AppDataContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    handleClickLogout(oktaAuth, setLoadingMessage);
  };

  const renderRedesignSwitch = () => (
    <Feature name={featureFlags.ENABLE_REDESIGN}>
      <MenuItem>
        <RedesignSwitch />
      </MenuItem>
    </Feature>
  );

  return agentDisplayName ? (
    <Box
      mx={2}
      style={{ height: `64px` }}
      display="flex"
      flexDirection="column"
      justifyContent="center">
      <Typography {...props} color="primary" variant="subtitle1" onClick={handleClick}>
        {agentDisplayName ? agentDisplayName : 'Agent Name'}
      </Typography>
      <Feature name={featureFlags.ENABLE_REDESIGN}>
        <Menu
          id="ao-agent-avatar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {renderRedesignSwitch()}
        </Menu>
      </Feature>
    </Box>
  ) : (
    <>
      <IconButton id="ao-agent-avatar-button" onClick={handleClick}>
        <Avatar>{agentInitials}</Avatar>
      </IconButton>
      <Menu
        id="ao-agent-avatar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {renderRedesignSwitch()}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

AgentDisplay.propTypes = {
  agentDisplayName: PropTypes.string,
  agentInitials: PropTypes.string,
};

export default AgentDisplay;
