const ROUTE_ADMINISTRATION = '/administration';

const ROUTE_AGENCY_TOOLKIT = '/agency-toolkit';
const ROUTE_AGENCY_TOOLKIT_ABSREPORTS = '/absreports';
const ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT = '/annual-mileage-validation-report';
const ROUTE_AGENCY_TOOLKIT_APNEREPORT = '/apnereport';
const ROUTE_AGENCY_TOOLKIT_BMREPORTS = '/bmreports';
const ROUTE_AGENCY_TOOLKIT_CCREPORTS = '/ccreports';
const ROUTE_AGENCY_TOOLKIT_DBCREPORT = '/dbcreport';
const ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS = '/lbareports';
const ROUTE_AGENCY_TOOLKIT_PLRREPORTS = '/plrreports';
const ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS = '/tareports';

const ROUTE_AGENT_MANAGEMENT = '/agent-management';
const ROUTE_AGENT_MANAGEMENT_ADD_AGENT = '/add-agent';
const ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT = '/search-agent';

const ROUTE_ANNOUNCEMENTS = '/announcements';

const ROUTE_APPLICATION_ADMIN = '/application-admin';

const ROUTE_BILLING = '/billing';
const ROUTE_BILLING_SERVICES = '/billing-services';
const ROUTE_BILLING_SERVICES_REPORTS = `${ROUTE_BILLING_SERVICES}/reports`;
const ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE = `${ROUTE_BILLING_SERVICES_REPORTS}/payments-made`;
const ROUTE_COMMERCIAL_LINES_AUDIT_FORMS = `/commercial-lines-audit-forms`;
const ROUTE_COMMERCIAL_LINES_QUOTING = '/commercial-lines-quoting';
const ROUTE_ACCOUNT_DETAIL = `/account-detail`;
const ROUTE_CL_PRODUCT_DETAIL = `${ROUTE_COMMERCIAL_LINES_QUOTING}/product-detail`;
const ROUTE_CLAIMS = '/claims';
const ROUTE_CLAIMS_INQ = '/claims-inq';
const ROUTE_CLIENTS = '/clients';
const ROUTE_COMMERCIAL_LINES_SERVICES = '/commercial-lines-services';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_EXTERNAL = '/external';
const ROUTE_FARMRANCH = '/farmranch';
// const ROUTE_FIND_PRODUCT_TO_QUOTE = `${ROUTE_COMMERCIAL_LINES_QUOTING}/get-started`;
const ROUTE_FLOOD = `/flood`;
const ROUTE_HOME = '/';
const ROUTE_ICS_HOME = '/home';
// const ROUTE_NEWS_ARTICLE_ADMIN = '/news-articles';
const ROUTE_NEWS_ARCHIVED = `/news-articles/home-page-archived`;
const ROUTE_NEWS_ARTICLE_ADMIN = '/news-admin';
const ROUTE_NEWS_ADD = '/news-admin/add-news';
const ROUTE_NEWS_EDIT = '/news-admin/edit-news';
const ROUTE_NEWS_ARTICLE_ADMIN_SEARCH = '/news-admin/news-articles';
const ROUTE_PERSONAL_LINES_SERVICES = '/personal-lines-services';
const ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM = '/personalLinesUmbrellaRatingToolAndForm';
const ROUTE_PL_UMBRELLA = '/plumbrella';
const ROUTE_QUOTING_ONE_POC = '/quoting-one-poc';
const ROUTE_QUOTES = '/quotes';
const ROUTE_REPORTS = '/reports';
const ROUTE_RESOURCES = '/resources';
const ROUTE_STORM_REFERENCE = '/storm-reference';
const ROUTE_UNDERWRITING = '/underwriting';
const ROUTE_USER_ADMIN = '/user-admin';
const ROUTE_USER_ADMIN_ADD_GROUP = '/add-group';
const ROUTE_USER_ADMIN_ADD_USER = '/add-user';
const ROUTE_USER_ADMIN_EDIT_USER = '/edit-user';
const ROUTE_USER_ADMIN_GROUP_INFORMATION = '/group-information';
const ROUTE_USER_ADMIN_SEARCH_USER = '/search-user';
const ROUTE_USER_ADMIN_VIEW_AGENCY_INFO = '/view-agency-info';
const ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY = '/view-change-history';
const ROUTE_CLIX = '/clix';
const ROUTE_WILDCARD = '/*';

export {
  ROUTE_ADMINISTRATION,
  ROUTE_AGENCY_TOOLKIT_ABSREPORTS,
  ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_AGENCY_TOOLKIT_APNEREPORT,
  ROUTE_AGENCY_TOOLKIT_BMREPORTS,
  ROUTE_AGENCY_TOOLKIT_CCREPORTS,
  ROUTE_AGENCY_TOOLKIT_DBCREPORT,
  ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS,
  ROUTE_AGENCY_TOOLKIT_PLRREPORTS,
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_AGENT_MANAGEMENT,
  ROUTE_AGENT_MANAGEMENT_ADD_AGENT,
  ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT,
  ROUTE_AGENCY_TOOLKIT,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_APPLICATION_ADMIN,
  ROUTE_BILLING,
  ROUTE_BILLING_SERVICES,
  ROUTE_BILLING_SERVICES_REPORTS,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE,
  ROUTE_CL_PRODUCT_DETAIL,
  ROUTE_ACCOUNT_DETAIL,
  ROUTE_CLAIMS,
  ROUTE_CLAIMS_INQ,
  ROUTE_CLIENTS,
  ROUTE_COMMERCIAL_LINES_AUDIT_FORMS,
  ROUTE_COMMERCIAL_LINES_SERVICES,
  ROUTE_DASHBOARD,
  ROUTE_EXTERNAL,
  ROUTE_FARMRANCH,
  // ROUTE_FIND_PRODUCT_TO_QUOTE,
  ROUTE_FLOOD,
  ROUTE_HOME,
  ROUTE_ICS_HOME,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_EDIT,
  ROUTE_NEWS_ARCHIVED,
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
  ROUTE_PERSONAL_LINES_SERVICES,
  ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM,
  ROUTE_PL_UMBRELLA,
  ROUTE_QUOTING_ONE_POC,
  ROUTE_QUOTES,
  ROUTE_STORM_REFERENCE,
  ROUTE_REPORTS,
  ROUTE_RESOURCES,
  ROUTE_UNDERWRITING,
  ROUTE_USER_ADMIN,
  ROUTE_USER_ADMIN_ADD_GROUP,
  ROUTE_USER_ADMIN_EDIT_USER,
  ROUTE_USER_ADMIN_ADD_USER,
  ROUTE_USER_ADMIN_GROUP_INFORMATION,
  ROUTE_USER_ADMIN_SEARCH_USER,
  ROUTE_USER_ADMIN_VIEW_AGENCY_INFO,
  ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY,
  ROUTE_CLIX,
  ROUTE_WILDCARD,
};
