import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar, Box, Divider } from '@material-ui/core';

import { SvgLogo } from '../SvgLogo';
import AgentDisplay from './AgentDisplay';
import agentContext from '../../contexts/agent.context';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
  },
  toolBar: { display: 'flex', justifyContent: 'space-between' },
  logo: { display: 'flex', alignItems: 'center' },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Header = () => {
  const classes = useStyles();
  const agentContextObj = useContext(agentContext);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.logo}>
            <SvgLogo route="/" />
            <Box pr={2} />
            <Divider orientation="vertical" flexItem></Divider>
            <Box pr={2} />
            <Typography color="primary" component="div">
              <Box fontSize={32} fontWeight="bold">
                Agents Only
              </Box>
            </Typography>
          </div>
          <div className={classes.name}>
            <AgentDisplay
              agentInitials={
                agentContextObj?.Name
                  ? `${agentContextObj.Name.First.substring(
                      0,
                      1,
                    )}${agentContextObj.Name.Last.substring(0, 1)}`
                  : 'AO'
              }
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  mobileControlFunc: PropTypes.func,
};

export default Header;
