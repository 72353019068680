import React from 'react';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import UserAdminViewContainer from './AgentAdminViewContainer';
import { ROUTE_AGENT_MANAGEMENT } from '../../constants/routes';
import { AOCard } from '../AOCard';

const AgentAdminEditAgent = () => {
  return (
    <Feature name={featureFlags.ACCESS_AGENT_MANAGEMENT}>
      <UserAdminViewContainer
        title="Search/Edit Agent"
        returnButtonRoute={ROUTE_AGENT_MANAGEMENT}
        returnButtonText="Find an Agent Administration">
        <AOCard cardTitle="" variant="outlined">
          to do
        </AOCard>
      </UserAdminViewContainer>
    </Feature>
  );
};

export default AgentAdminEditAgent;
