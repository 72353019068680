import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';

import {
  ROUTE_ICS_HOME,
  ROUTE_HOME,
  ROUTE_CLIENTS,
  ROUTE_CLAIMS,
  ROUTE_QUOTES,
  ROUTE_BILLING,
  ROUTE_REPORTS,
  ROUTE_RESOURCES,
} from '../../constants/routes';
import { Feature } from '../Feature';

const drawerLength = 72;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerLength,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerLength,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  navItem: {
    color: theme.palette.text.secondary,
    height: drawerLength,
    width: drawerLength,
    paddingTop: '0.8125rem',
    paddingBottom: '0.8125rem',
    flexDirection: 'column',
    position: 'relative',
  },
  navIcon: {
    justifyContent: 'center',
  },
  navSelected: {
    color: theme.palette.primary.main,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '0.25rem',
      backgroundColor: theme.palette.primary.main,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
  navLabel: {
    fontSize: '0.75rem',
  },
}));

const navigationItems = [
  {
    text: 'Home',
    featureFlag: '',
    route: ROUTE_ICS_HOME,
    icon: <HomeOutlinedIcon />,
  },
  {
    text: 'Dashboard',
    featureFlag: '',
    route: ROUTE_HOME,
    icon: <DashboardOutlinedIcon />,
  },
  { text: 'Clients', featureFlag: '', route: ROUTE_CLIENTS, icon: <RecentActorsOutlinedIcon /> },
  { text: 'Quotes', featureFlag: '', route: ROUTE_QUOTES, icon: <ListAltOutlinedIcon /> },
  {
    text: 'Billing',
    featureFlag: '',
    route: ROUTE_BILLING,
    icon: <PaymentOutlinedIcon />,
  },
  {
    text: 'Claims',
    featureFlag: '',
    route: ROUTE_CLAIMS,
    icon: <AssignmentOutlinedIcon />,
  },
  {
    text: 'Reports',
    featureFlag: '',
    route: ROUTE_REPORTS,
    icon: <AssessmentOutlinedIcon />,
  },
  {
    text: 'Resources',
    featureFlag: '',
    route: ROUTE_RESOURCES,
    icon: <CollectionsBookmarkOutlinedIcon />,
  },
];

const Navigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {navigationItems.map((item) => {
            const isNavItemSelected = pathname === item.route;

            const renderItem = () => (
              <ListItem
                button
                classes={{ root: classes.navItem, selected: classes.navSelected }}
                key={`ao-li-${item.text}`}
                onClick={() => history.push(item.route)}
                selected={isNavItemSelected}>
                <ListItemIcon className={classes.navIcon}>
                  {React.cloneElement(item.icon, { color: isNavItemSelected ? 'primary' : '' })}
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.navLabel }} primary={item.text} />
              </ListItem>
            );

            return item.featureFlag ? (
              <Feature key={`ao-ff-li-${item.text}`} name={item.featureFlag}>
                {renderItem()}
              </Feature>
            ) : (
              renderItem()
            );
          })}
        </List>
      </div>
    </Drawer>
  );
};

export default Navigation;
