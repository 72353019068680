import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import RedesignUIShell from './RedesignUIShell';
import ClassicUIShell from './ClassicUIShell';
import agentContext from '../../contexts/agent.context';

const UIShell = ({ children }) => {
  const agentContextObj = useContext(agentContext);

  return agentContextObj?.userPreferences?.redesignHeader ? (
    <RedesignUIShell>{children}</RedesignUIShell>
  ) : (
    <ClassicUIShell>{children}</ClassicUIShell>
  );
};

UIShell.propTypes = {
  children: PropTypes.node,
};

export default UIShell;
