import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';

import { ViewContainer } from '../Layout';

const RedesignRouteShell = ({ title }) => {
  // const history = useHistory();

  // const handleOnClick = (event, route) => {
  //   event.preventDefault();
  //   history.push(route);
  // };

  return (
    <ViewContainer title={title}>
      <Paper>
        <Box p={3}>
          {/* {routes.map((route, index) => (
            <Box key={route.path} pb={index !== routes.length - 1 ? 2 : 0}>
              <Link href="#" onClick={(event) => handleOnClick(event, route.path)}>
                {route.name}
              </Link>
            </Box>
          ))} */}
        </Box>
      </Paper>
    </ViewContainer>
  );
};

RedesignRouteShell.propTypes = {
  routes: PropTypes.array,
  title: PropTypes.string,
};

export default RedesignRouteShell;
