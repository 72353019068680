import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AOSelect } from '../../AOSelect';
import useInitializeFilter from './useInitializeFilter';
import { getEnumSearchCriteria } from './filterHelpers';

const ALL_PRODUCTS = 'ALL PRODUCTS';

const Product = ({ handleSetSearchCriteria, setInitialQuery, setResetFunc, typeOfContainer }) => {
  const [selectedProduct, setSelectedProduct] = useState(ALL_PRODUCTS);

  const resetComponent = () => {
    if (filterSpec?.searchTerm) {
      setSelectedProduct(ALL_PRODUCTS);
    }
  };

  // onChange handle for dropdown. If 'ALL PRODUCTS' is selected, we need to concatenate a string that contains all possible products.
  const handleOnChange = (e) => {
    const { value } = e.target;
    setSelectedProduct(value);

    let newValue = [];
    if (value === ALL_PRODUCTS) {
      newValue = getEnumSearchCriteria(filterSpec?.values);
    } else {
      newValue = getEnumSearchCriteria([value]);
    }
    handleSetSearchCriteria(filterSpec?.searchTerm, newValue);
  };

  const { filterSpec } = useInitializeFilter(typeOfContainer, 'ProductName', setInitialQuery);

  // on initialization, assign the reset pointer to ref and send initial search criteria
  useEffect(() => {
    if (filterSpec?.searchTerm?.length > 0) {
      resetComponent();
      setResetFunc(filterSpec?.searchTerm, resetComponent);
      handleSetSearchCriteria(filterSpec?.searchTerm, getEnumSearchCriteria(filterSpec?.values));
    }
  }, [filterSpec]);

  return (
    <AOSelect
      id="brandfolder-product-select"
      labelId="brandfolder-product-select-label"
      inputLabel="Product"
      disabled={!filterSpec?.searchTerm}
      name={filterSpec?.searchTerm}
      menuItems={
        filterSpec?.values
          ? [
              { value: ALL_PRODUCTS, code: ALL_PRODUCTS },
              ...filterSpec?.values?.map((product) => ({ value: product, code: product })),
            ]
          : [{ value: ALL_PRODUCTS, code: ALL_PRODUCTS }]
      }
      onChange={handleOnChange}
      value={selectedProduct}
    />
  );
};

Product.propTypes = {
  handleSetSearchCriteria: PropTypes.func,
  setInitialQuery: PropTypes.func,
  setResetFunc: PropTypes.func,
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
};

export default Product;
