import React from 'react';

import { ViewContainer } from '../Layout';
import { Row, RowCol, Col } from '../ScreenSmith';
import { AOCard } from '../AOCard';
import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { Typography } from '@material-ui/core';
import { AOLinkButton } from '../AOLinkButton';

const AppAdmin = () => {
  return (
    <Feature name={featureFlags.ACCESS_APP_ADMIN}>
      <ViewContainer title={'Application Administration'}>
        <RowCol mb={2}>
          <AOCard>
            <Row spacing={4}>
              <Col xs={4}>
                <Typography color="textSecondary">
                  The links to the right offer access to administrative resources needed to update
                  various application information.
                </Typography>
              </Col>
              <Col xs={8}>
                <Feature name={featureFlags.ACCESS_CONTENT_MGMT_ADMIN}>
                  <RowCol mb={4}>
                    <AOLinkButton routeLegacy={'/news_article_adm'} style={{ fontSize: '1rem' }}>
                      MSA News Articles
                    </AOLinkButton>
                    <Typography color="textSecondary">
                      Manage news articles found on MSA Group.
                    </Typography>
                  </RowCol>
                </Feature>

                <Feature name={featureFlags.ACCESS_CONTENT_MGMT_ADMIN}>
                  <RowCol mb={4}>
                    <AOLinkButton
                      routeLegacy={'/product_announcement'}
                      style={{ fontSize: '1rem' }}>
                      Product Announcements
                    </AOLinkButton>
                    <Typography color="textSecondary">
                      Manage Commercial and Personal lines product announcements.
                    </Typography>
                  </RowCol>
                </Feature>

                <Feature name={featureFlags.ACCESS_AGENT_MANAGEMENT}>
                  <RowCol mb={4}>
                    <AOLinkButton routeLegacy={'/agent_mgmt'} style={{ fontSize: '1rem' }}>
                      Find an Agent
                    </AOLinkButton>
                    <Typography color="textSecondary">
                      From this screen you may update contact and location information for agents.
                    </Typography>
                  </RowCol>
                </Feature>

                <Feature name={featureFlags.ACCESS_RESOURCE_MGMT_ADMIN}>
                  <RowCol mb={4}>
                    <AOLinkButton routeLegacy={'/resourceMgmt'} style={{ fontSize: '1rem' }}>
                      Resource Mgmt
                    </AOLinkButton>
                    <Typography color="textSecondary">
                      Control access to Agents Only site resources/applications.
                    </Typography>
                  </RowCol>
                </Feature>

                <Feature name={featureFlags.ACCESS_SUPER_ADMIN}>
                  <RowCol mb={4}>
                    <AOLinkButton
                      routeLegacy={'/app_navigation_control'}
                      style={{ fontSize: '1rem' }}>
                      State Rollout Administration
                    </AOLinkButton>
                    <Typography color="textSecondary">
                      Control the navigation to various applications from this page. You will be
                      able to manage application rollout by Line of Business, State or Location.
                    </Typography>
                  </RowCol>
                </Feature>
              </Col>
            </Row>
          </AOCard>
        </RowCol>
      </ViewContainer>
    </Feature>
  );
};

export default AppAdmin;
