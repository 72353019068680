import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import { SecurityProvider } from '../SecurityProvider';
import { UIShell } from '../UIShell';
import { NotFoundPage } from '../NotFoundPage';

import RouteWrapper from './RouteWrapper';

import {
  ROUTE_BILLING,
  ROUTE_CLAIMS,
  ROUTE_DASHBOARD,
  ROUTE_ICS_HOME,
  ROUTE_QUOTES,
  ROUTE_REPORTS,
  ROUTE_RESOURCES,
  ROUTE_WILDCARD,
} from '../../constants/routes';

import { ABSReport } from '../ABSReport';
import { Announcements } from '../Announcements';
import { APnEReport } from '../APnEReport';
import { AgencyToolkit } from '../AgencyToolkit';
import { AgentAdmin, AgentAdminAddAgent, AgentAdminSearchAgent } from '../AgentAdmin';
import { AnnualMileageValidationReport } from '../AnnualMileageValidationReport';
import { AppAdmin } from '../AppAdmin';
import { BillingServices, BillingServicesReportsPaymentsMade } from '../BillingServices';
import { BillingInquiryDetails } from '../BillingInquiryDetails';
import { BMReports } from '../BMReports';
import { CCReport } from '../CCReport';
import { Claims } from '../Claims';
import { CommercialLinesServices, CLSAuditForms } from '../CommercialLinesServices';
import { DbcReport } from '../DbcReport';
import { FarmRanch } from '../FarmRanch';
// import { FindProductToQuote } from '../FindProductToQuote';
import { Flood } from '../Flood';
import { HomePage } from '../HomePage';
import { LossesByAgentReport } from '../LossesByAgentReport';
import { NewsArticlesArchived } from '../NewsArticles';
import { NewsAdmin } from '../NewsAdmin';
import { PLUmbrella } from '../PLUmbrella';
import { PersonalLinesServices } from '../PersonalLinesServices';
import { PolicyLossRunsReport } from '../PolicyLossRunsReport';
import { ProductDetail } from '../ProductDetail';
import { QuotingOnePoc } from '../POC';
import { StormReference } from '../StormReference';
import { TransactionActivityReports } from '../TransactionActivityReports';
import { Underwriting } from '../Underwriting';
import {
  UserAdmin,
  UserAdminAddGroup,
  UserAdminAddUser,
  UserAdminEditUser,
  UserAdminGroupInformation,
  UserAdminSearchUser,
  UserAdminViewAgencyInfo,
  UserAdminViewChangeHistory,
} from '../UserAdmin';
import { External } from '../External';
import { AddNewsArticle, EditNewsArticle } from '../NewsArticleAdministration/AddOrEditNewsArticle';
import { SearchNewsArticles } from '../NewsArticleAdministration/SearchNewsArticles';
import { Clients } from '../Clients';

import {
  ROUTE_ACCOUNT_DETAIL,
  ROUTE_AGENCY_TOOLKIT,
  ROUTE_AGENCY_TOOLKIT_ABSREPORTS,
  ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_AGENCY_TOOLKIT_APNEREPORT,
  ROUTE_AGENCY_TOOLKIT_BMREPORTS,
  ROUTE_AGENCY_TOOLKIT_CCREPORTS,
  ROUTE_AGENCY_TOOLKIT_DBCREPORT,
  ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS,
  ROUTE_AGENCY_TOOLKIT_PLRREPORTS,
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_AGENT_MANAGEMENT,
  ROUTE_AGENT_MANAGEMENT_ADD_AGENT,
  ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_APPLICATION_ADMIN,
  ROUTE_BILLING_SERVICES,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE,
  ROUTE_CLAIMS_INQ,
  ROUTE_CLIENTS,
  ROUTE_CL_PRODUCT_DETAIL,
  ROUTE_COMMERCIAL_LINES_AUDIT_FORMS,
  ROUTE_COMMERCIAL_LINES_SERVICES,
  ROUTE_EXTERNAL,
  ROUTE_FARMRANCH,
  // ROUTE_FIND_PRODUCT_TO_QUOTE,
  ROUTE_FLOOD,
  ROUTE_HOME,
  ROUTE_NEWS_ARCHIVED,
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_PERSONAL_LINES_SERVICES,
  ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM,
  ROUTE_PL_UMBRELLA,
  ROUTE_QUOTING_ONE_POC,
  ROUTE_STORM_REFERENCE,
  ROUTE_UNDERWRITING,
  ROUTE_USER_ADMIN,
  ROUTE_USER_ADMIN_ADD_GROUP,
  ROUTE_USER_ADMIN_ADD_USER,
  ROUTE_USER_ADMIN_EDIT_USER,
  ROUTE_USER_ADMIN_GROUP_INFORMATION,
  ROUTE_USER_ADMIN_SEARCH_USER,
  ROUTE_USER_ADMIN_VIEW_AGENCY_INFO,
  ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_EDIT,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
  ROUTE_CLIX,
} from '../../constants/routes';
import { Quotes } from '../Quotes';
import Billing from '../Billing/Billing';
import { ClaimsRouteShell } from '../Claims/ClaimsRouteShell';
import Reports from '../Reports/Reports';
import { Resources } from '../Resources';
import { ICSHome } from '../ICSHome';

// For scrolling to top after router completes route change
// https://v5.reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RenderedApp = () => (
  <Router>
    <ScrollToTop />
    <SecurityProvider>
      <Switch>
        <RouteWrapper path={ROUTE_HOME} exact component={HomePage} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM}
          exact
          component={NotFoundPage}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_PERSONAL_LINES_SERVICES}
          exact
          component={PersonalLinesServices}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_COMMERCIAL_LINES_SERVICES}
          exact
          component={CommercialLinesServices}
          layout={UIShell}
        />
        <RouteWrapper
          path={`${ROUTE_ANNOUNCEMENTS}`}
          exact
          component={Announcements}
          layout={UIShell}
        />
        <RouteWrapper
          path={`${ROUTE_ANNOUNCEMENTS}/:lineOfBusiness`}
          exact
          component={Announcements}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS}
          exact
          component={TransactionActivityReports}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_AGENT_MANAGEMENT} exact component={AgentAdmin} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_AGENT_MANAGEMENT_ADD_AGENT}
          exact
          component={AgentAdminAddAgent}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT}
          exact
          component={AgentAdminSearchAgent}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_ABSREPORTS}
          exact
          component={ABSReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT}
          exact
          component={AnnualMileageValidationReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_APNEREPORT}
          exact
          component={APnEReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_BMREPORTS}
          exact
          component={BMReports}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_CCREPORTS}
          exact
          component={CCReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_DBCREPORT}
          exact
          component={DbcReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS}
          exact
          component={LossesByAgentReport}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT_PLRREPORTS}
          exact
          component={PolicyLossRunsReport}
          layout={UIShell}
        />
        {/* <RouteWrapper
          path={ROUTE_FIND_PRODUCT_TO_QUOTE}
          exact
          component={FindProductToQuote}
          layout={UIShell}
        /> */}
        <RouteWrapper
          path={`${ROUTE_CL_PRODUCT_DETAIL}/:productId`}
          exact
          component={ProductDetail}
          layout={UIShell}
        />
        <RouteWrapper
          path={`${ROUTE_ACCOUNT_DETAIL}/:accountNumber`}
          exact
          component={BillingInquiryDetails}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_AGENCY_TOOLKIT}
          exact
          component={AgencyToolkit}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_COMMERCIAL_LINES_AUDIT_FORMS}
          exact
          component={CLSAuditForms}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_UNDERWRITING} exact component={Underwriting} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_BILLING_SERVICES}
          exact
          component={BillingServices}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE}
          exact
          component={BillingServicesReportsPaymentsMade}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_QUOTING_ONE_POC}
          exact
          component={QuotingOnePoc}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_NEWS_ARCHIVED}
          exact
          component={NewsArticlesArchived}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_NEWS_ARTICLE_ADMIN}
          exact
          component={NewsAdmin}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_PL_UMBRELLA} exact component={PLUmbrella} layout={UIShell} />
        <RouteWrapper path={ROUTE_FARMRANCH} exact component={FarmRanch} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLAIMS_INQ} exact component={Claims} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_STORM_REFERENCE}
          exact
          component={StormReference}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_FLOOD} exact component={Flood} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN} exact component={UserAdmin} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_ADD_GROUP}
          exact
          component={UserAdminAddGroup}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_ADD_USER}
          exact
          component={UserAdminAddUser}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_GROUP_INFORMATION}
          exact
          component={UserAdminGroupInformation}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_EDIT_USER}
          exact
          component={UserAdminEditUser}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_SEARCH_USER}
          exact
          component={UserAdminSearchUser}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_VIEW_AGENCY_INFO}
          exact
          component={UserAdminViewAgencyInfo}
          layout={UIShell}
        />
        <RouteWrapper
          path={ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY}
          exact
          component={UserAdminViewChangeHistory}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_EXTERNAL} exact component={External} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ADD} exact component={AddNewsArticle} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_EDIT} exact component={EditNewsArticle} layout={UIShell} />
        <RouteWrapper path={ROUTE_ICS_HOME} exact component={ICSHome} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLIENTS} exact component={Clients} layout={UIShell} />
        <RouteWrapper path={ROUTE_QUOTES} exact component={Quotes} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING} exact component={Billing} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLAIMS} exact component={ClaimsRouteShell} layout={UIShell} />
        <RouteWrapper path={ROUTE_REPORTS} exact component={Reports} layout={UIShell} />
        <RouteWrapper path={ROUTE_RESOURCES} exact component={Resources} layout={UIShell} />

        <RouteWrapper path={ROUTE_APPLICATION_ADMIN} exact component={AppAdmin} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_NEWS_ARTICLE_ADMIN_SEARCH}
          exact
          component={SearchNewsArticles}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_CLIX} exact component={BillingServices} layout={UIShell} />
        {/* order of rendering routes matters */}
        <Route path={`${ROUTE_DASHBOARD}`} component={LoginCallback} />
        <RouteWrapper path={`${ROUTE_WILDCARD}`} component={NotFoundPage} layout={UIShell} />
      </Switch>
    </SecurityProvider>
  </Router>
);

export default RenderedApp;
